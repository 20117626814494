import { template as template_31eb9f6a539b4a4fb6d792b87d2ba4e4 } from "@ember/template-compiler";
const SidebarSectionMessage = template_31eb9f6a539b4a4fb6d792b87d2ba4e4(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
