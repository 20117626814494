import { template as template_4aef8e1d959741fcadf02145cc6279cf } from "@ember/template-compiler";
import Component from "@glimmer/component";
import didInsert from "@ember/render-modifiers/modifiers/did-insert";
import didUpdate from "@ember/render-modifiers/modifiers/did-update";
import { bind } from "discourse-common/utils/decorators";
export default class IframedHtml extends Component {
    @bind
    writeHtml(element) {
        const iframeDoc = element.contentWindow.document;
        iframeDoc.open("text/html", "replace");
        iframeDoc.write(this.args.html);
        iframeDoc.close();
    }
    static{
        template_4aef8e1d959741fcadf02145cc6279cf(`
    {{! template-lint-disable require-iframe-title }}
    <iframe
      {{didInsert this.writeHtml}}
      {{didUpdate this.witeHtml @html}}
      sandbox="allow-same-origin"
      class={{if @html "iframed-html"}}
      ...attributes
    ></iframe>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
