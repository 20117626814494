import { template as template_ce46aa9b6ab54c249d8b14689821cdf9 } from "@ember/template-compiler";
const FKText = template_ce46aa9b6ab54c249d8b14689821cdf9(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
